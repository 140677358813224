<template>
  <div ref="buildingsContainer" class="buildings-container">
    <div class="buildings-list">
      <MobileBuildingWrapper
        v-for="(building, index) in buildingsList"
        :key="building.buildingKey"
        :style="{ animationDelay: `${index * 0.3}s` }"
        :building="building"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useEnvs from '~/composables/useEnvs';
import type { BuildingProps } from '~/types/crafting';
import { MINING_BUILDINGS } from '~/utils/constants/miningBuildings';
import { Tokens } from '~/utils/constants';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
const { apiUrl } = useEnvs();
const buildingsContainer = ref();
const buildingsList = ref<BuildingProps[]>([]);
const store = useMainStore();
const { address } = useWeb3ModalAccount();

watch(
  address,
  async () => {
    store.currentDistrict = null;

    const miningBuildings = Object.entries(MINING_BUILDINGS).map(([key, value]) => ({
      ...value,
      miningToken: key as Tokens
    }));

    await fetch(apiUrl + '/crafting/buildings?withRecipes=true')
      .then((response) => response.json())
      .then((data) => {
        buildingsList.value = data
          .filter((building: BuildingProps) => building.buildingKey && !building.buildingKey.includes('mine'))
          .concat({ buildingName: '', buildingKey: 'dock', buildingHash: '', buildingId: '', descriptionKey: '' })
          .concat({ buildingName: '', buildingKey: 'barbershop', buildingHash: '', buildingId: '', descriptionKey: '' })
          .concat(miningBuildings);
      });
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.buildings-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 60px);

  @media (max-width: 376px) {
    height: calc(100% - 30px);
  }

  .buildings-list {
    display: flex;
    flex-direction: column;
    gap: 62px;
    margin: 60px 16px 0;

    @media (max-width: 376px) {
      margin: 60px 8px 0;
    }

    .building-wrapper {
      opacity: 0;
      animation: ascent 0.5s forwards ease;
      animation-delay: var(--delay);
    }

    @keyframes ascent {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.margin {
  margin-top: 44px;

  @media (max-width: 376px) {
    margin-top: 14px;
  }
}
</style>
